import { Button, Container, FlagPill, PictureTile, Tabs, Text, Title } from "@/components/common";
import { BsArrowDownShort, BsArrowRightShort } from "react-icons/bs";
import Link from "next/link";
import Head from "next/head";
import { APP_NAME, APP_SHORT_DESCRIPTION } from "@/config/common";
import { PublicLayout } from "@/layouts";
import Image from "next/image";
import appWithFeaturesImg from "@/assets/app-with-features.png";
import appWithContractImg from "@/assets/app-with-contract.png";
import androidHomeScreen1Img from "@/assets/android-homescreen-1.jpg";
import androidHomeScreen2Img from "@/assets/android-homescreen-2.jpg";
import androidHomeScreen3Img from "@/assets/android-homescreen-3.jpg";
import { FeatureCard, FeatureCardContent, HowItWorksItem } from "@/components/page";
import { ContractRole } from "@/functions/src/models/contract";
import { useRef, useState } from "react";
import dynamic from "next/dynamic";
import { IoShareOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useRouter } from "next/router";
import { countryGhana } from "@/functions/src/models/country";

const Overlay = dynamic(
  async () => {
    const { Overlay } = await import("@/components/common/overlay");
    return Overlay;
  },
  { ssr: false }
);

export default function Home() {
  const router = useRouter();
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [howItWorksRole, setHowItWorksRole] = useState<ContractRole>(ContractRole.BUYER);

  const handleLearnMore = () => {
    scrollTargetRef.current?.scrollIntoView({
      behavior: "smooth"
    });
  };

  return (
    <PublicLayout>
      <Head>
        <title>
          {APP_NAME} - {APP_SHORT_DESCRIPTION}
        </title>
      </Head>

      <div className="flex flex-col py-8 gap-12">
        <div className="container min-h-[calc(100vh-60px)] px-4 md:px-8 grid grid-cols-1 lg:grid-cols-4 gap-12 lg:gap-0 lg:items-center">
          <div className="flex flex-col gap-6 h-[calc(100vh-92px)] lg:h-auto justify-center lg:col-span-1">
            <div className="flex items-center gap-3 justify-center lg:justify-start">
              <Text size="lg" className="text-gray-500">
                {APP_NAME}
              </Text>

              <FlagPill country={countryGhana} />
            </div>

            <div>
              <Title className="text-center lg:text-left leading-tight" size="xl">
                Transact Online
                <br />
                Without Scams!
              </Title>

              <Text className="text-center lg:text-left leading-tight text-gray-500" italic>
                Escrow Payments Made Easy
              </Text>
            </div>

            <Text className="text-center lg:text-left">
              {APP_NAME} makes online transactions safe again.
              <br />
              Only users with <strong>verified government identities</strong> are allowed here so you don’t get scammed
              online <i>and offline too</i>.
            </Text>

            <Link href="/login">
              <Button size="lg" flex>
                Get Started <BsArrowRightShort />
              </Button>
            </Link>

            <Button variant="secondary" onClick={handleLearnMore} flex>
              Learn More <BsArrowDownShort />
            </Button>
          </div>

          <Image
            src={appWithFeaturesImg}
            className="hidden w-full mx-auto md:w-1/2 lg:w-3/4 lg:block lg:col-span-3"
            alt="Jumbotron"
          />
        </div>

        <div ref={scrollTargetRef} className="bg-gray-100 pt-20">
          <div className="container px-8 flex flex-col gap-8">
            <Title align="center" size="lg">
              Why {APP_NAME}?
            </Title>

            <div className="flex flex-col items-center gap-2">
              <Text className="text-gray-600 max-w-5xl" align="center" italic>
                &quot;Reports show that scams on social media are a problem for people of all ages, but the numbers are
                most striking for younger people. In the first six months of 2023, in reports of money lost to fraud by
                people 20-29, social media was the contact method more than <strong>38%</strong> of the time. For people
                18-19, that figure was <strong>47%</strong>. The numbers decrease with age, consistent with generational
                differences in social media use.&quot;
              </Text>

              <div className="flex items-center gap-4">
                <div className="w-4 h-[1px] bg-gray-600" />

                <Text className="text-gray-500" size="sm" italic>
                  Federal Trade Commission (US), 2023
                </Text>

                <div className="w-4 h-[1px] bg-gray-600" />
              </div>
            </div>

            <div className="flex flex-col gap-8 lg:flex-row lg:items-center lg:justify-center lg:gap-20 pb-20 overflow-hidden">
              <FeatureCard feature={FeatureCardContent.BUYER_PROTECTION} />
              <FeatureCard feature={FeatureCardContent.SELLER_PROTECTION} />
              <FeatureCard feature={FeatureCardContent.FRAUD_PREVENTION} />
            </div>
          </div>
        </div>

        <div className="container px-8 max-w-4xl flex flex-col gap-8">
          <Title align="center" size="lg">
            How It Works
          </Title>

          <div className="relative max-w-md mx-auto flex items-center">
            <Button
              variant={howItWorksRole === "Buyer" ? undefined : "secondary"}
              onClick={() => setHowItWorksRole(ContractRole.BUYER)}
              borderless>
              As a buyer
            </Button>

            <Button
              variant={howItWorksRole === "Seller" ? undefined : "secondary"}
              onClick={() => setHowItWorksRole(ContractRole.SELLER)}
              borderless>
              As a seller
            </Button>

            <div className="absolute w-full h-full z-10 rounded-full border-2 border-black pointer-events-none" />
          </div>

          {howItWorksRole === "Buyer" && (
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
              <HowItWorksItem
                step={1}
                title="Create a Contract"
                description="Describe the contract agreement with your seller. Enter the agreed amount, deadline and upload pictures of the result (if any)."
              />

              <HowItWorksItem
                step={2}
                title="Find Your Seller"
                description={`Search for your seller on ${APP_NAME} using their email. A seller will only appear if he/she has been verified on the platform.`}
              />

              <HowItWorksItem
                step={3}
                title="Make Payment"
                description="Once your contract is created, make the agreed payment. You seller won’t begin work until your payment reflects on the contract."
              />

              <HowItWorksItem
                step={4}
                title="Wait For Product"
                description="Once you receive your product and it matches with the contract description, you release the contract payment to your seller."
              />
            </div>
          )}

          {howItWorksRole === "Seller" && (
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
              <HowItWorksItem
                step={1}
                title="Receive Contract"
                description="Receive the contract from your buyer and confirm the details of the contract as well as the agreed amount and deadline."
              />

              <HowItWorksItem
                step={2}
                title="Wait For Payment"
                description={`Wait for your buyer to make the agreed payment to ${APP_NAME}. You will be able to see any activity that occurs on your contract.`}
              />

              <HowItWorksItem
                step={3}
                title="Deliver Product"
                description={`Once your buyer has made payment to ${APP_NAME}, you can begin work on the contract deliverables before the deadline.`}
              />

              <HowItWorksItem
                step={4}
                title="Get Paid"
                description={`After you have delivered the product to the buyer and it meets the contract description, ${APP_NAME} will release your payment.`}
              />
            </div>
          )}
        </div>

        <div className="bg-black py-12 lg:my-48">
          <div className="container flex flex-col lg:flex-row justify-center items-center gap-12 lg:gap-24 px-8">
            <div className="flex flex-col items-center gap-8 lg:max-w-md">
              <div className="flex flex-col gap-2">
                <Title size="lg" className="text-white" align="center">
                  Get {APP_NAME}!
                </Title>

                <Text align="center" className="text-left text-white max-w-md mx-auto">
                  Our platform is a mobile-first web app.
                  <br />
                  No need to download from Playstore or Appstore.
                </Text>
              </div>

              <div className="flex flex-col gap-6 w-full">
                <Link href="/login">
                  <Button size="lg" className="!bg-white !text-black" flex>
                    Simply Get Started <BsArrowRightShort />
                  </Button>
                </Link>

                <div className="flex items-center justify-center gap-6">
                  <div className="w-12 h-[1px] bg-gray-300" />
                  <Text className="text-white">OR</Text>
                  <div className="w-12 h-[1px] bg-gray-300" />
                </div>

                <Link href="?platform=android">
                  <Button size="lg" className="!border-white text-white" variant="secondary" flex>
                    Add To Home Screen
                  </Button>
                </Link>
              </div>
            </div>

            <div className="relative lg:w-[330px]">
              <Image src={appWithContractImg} className="w-full lg:absolute lg:-translate-y-1/2" alt="Get started" />
            </div>
          </div>
        </div>
      </div>

      <Overlay show={!!router.query.platform} onClose="/">
        <div className="bg-[#f3f3f3] rounded-tl-2xl rounded-tr-2xl flex flex-col gap-4 py-4 relative">
          <Link href="/" className="absolute top-4 right-4">
            <Button type="button" variant="secondary" className="!p-2 fill-black text-black">
              <MdClose />
            </Button>
          </Link>

          <Container>
            <Tabs
              query="platform"
              tabs={[
                { id: "android", label: "Android", default: true },
                { id: "iphone", label: "Iphone" }
              ]}>
              <div id="android-steps" className="py-4 flex flex-col gap-4">
                <ol className="list-decimal flex flex-col gap-4">
                  <Text as="li">
                    Tap on the &quot;Share&quot; icon (︙) n the middle of the browser bar at the bottom
                  </Text>

                  <Text as="li">
                    Tap <strong>Add to Home screen</strong> in the bottom right corner of the menu
                  </Text>

                  <Text as="li">
                    Tap <strong>Add</strong>
                  </Text>
                </ol>

                <Text>
                  You should now see <strong>{APP_NAME}</strong> on your home screen.
                </Text>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className="md:col-span-2 lg:col-span-1">
                    <PictureTile src={androidHomeScreen1Img.src} />
                  </div>

                  <div className="md:col-span-1 lg:col-span-1">
                    <PictureTile src={androidHomeScreen2Img.src} />
                  </div>

                  <div className="md:col-span-1 lg:col-span-1">
                    <PictureTile src={androidHomeScreen3Img.src} />
                  </div>
                </div>
              </div>

              <div id="iphone-steps" className="py-4 flex flex-col gap-2">
                <ol className="list-decimal flex flex-col gap-4">
                  <Text as="li">
                    Tap on the &quot;More&quot; icon with three vertical dots (
                    <span className="inline-block">
                      <IoShareOutline />
                    </span>
                    ) in the upper right corner of the browser
                  </Text>

                  <Text as="li">
                    Tap <strong>Add to Home screen</strong> close to the bottom of the menu
                  </Text>

                  <Text as="li">
                    Tap <strong>Add</strong>
                  </Text>
                </ol>

                <Text>
                  You should now see <strong>{APP_NAME}</strong> on your home screen.
                </Text>
              </div>
            </Tabs>
          </Container>
        </div>
      </Overlay>
    </PublicLayout>
  );
}
